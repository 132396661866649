import React from "react"
import BaseDialog from "./base-dialog"

const GloryBeDialog = ({ open, onClose }) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Glory Be"
      content="Glory be to the Father, and to the Son, and to the Holy Spirit, as it was in the beginning, is now, and ever shall be, world without end."
    />
  )
}

export default GloryBeDialog
