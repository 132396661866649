import React from "react"

const Footer = () => {
  return (
    <footer className="footer-wrapper">
      <div className="footer-container">
        <h3>&copy; 2022 - Talibongnons of United States and Canada</h3>
      </div>
    </footer>
  )
}

export default Footer
