import React from "react"
import BaseDialog from "./base-dialog"

const HailMaryDialog = ({ open, onClose }) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Hail Mary"
      content="Hail Mary full of Grace, the Lord is with thee. Blessed are thou amongst women and blessed is the fruit of thy womb Jesus.<br /><br />Holy Mary Mother of God, pray for us sinners now and at the hour of our death. Amen."
    />
  )
}

export default HailMaryDialog
