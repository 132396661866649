import React, { useState } from "react"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import LogoImage from "../images/logo.jpg"
import { Link } from "gatsby"

const Header = () => {
  const [mobileMenuState, setMobileMenuState] = useState(false)

  const menuLinks = [
    {
      path: "/novena-prayers/",
      title: "Novena Prayers",
    },
    {
      path: "/about/",
      title: "About",
    },
  ]

  return (
    <header className="header-wrapper">
      <div className="header-container">
        <div className="logo">
          <Link to="/">
            <img
              src={LogoImage}
              title="Viva Santisima Trinidad"
              alt="Viva Santisima Trinidad"
            />
          </Link>
        </div>
        <nav className="nav-items">
          {menuLinks.map(link => {
            return (
              <div className="nav-item" key={link.path}>
                <Link to={link.path}>{link.title}</Link>
              </div>
            )
          })}
          <div className="nav-item hamburger">
            <FontAwesomeIcon
              icon={faBars}
              onClick={() => setMobileMenuState(!mobileMenuState)}
            />
          </div>
        </nav>
      </div>
      <div className={`subheader-container ${mobileMenuState ? "show" : ""}`}>
        <nav className="nav-items">
          {menuLinks.map(link => {
            return (
              <div className="nav-item" key={link.path}>
                <Link to={link.path}>{link.title}</Link>
              </div>
            )
          })}
        </nav>
      </div>
    </header>
  )
}

export default Header
