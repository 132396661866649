import React from "react"
import BaseDialog from "./base-dialog"

const OurFatherDialog = ({ open, onClose }) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Our Father"
      content={`Our Father, Who art in heaven, hallowed be Thy name; Thy kingdom come; Thy will be done on earth as it is in heaven.<br /><br />Give us this day our daily bread; and forgive us our trespasses as we forgive those who trespass against us; and lead us not into temptation, but deliver us from evil.`}
    />
  )
}

export default OurFatherDialog
