import React, { useContext } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../context/global-context"
import OurFatherDialog from "./novena-prayers/dialog-boxes/our-father-dialog"
import HailMaryDialog from "./novena-prayers/dialog-boxes/hail-mary-dialog"
import GloryBeDialog from "./novena-prayers/dialog-boxes/glory-be-dialog"
import "./layout.css"

const Layout = ({ children }) => {
  const globalDispatch = useContext(GlobalDispatchContext)
  const globalState = useContext(GlobalStateContext)
  return (
    <div className="page-wrapper">
      <Header />

      <div className="content-wrapper">
        <div className="content-container">{children}</div>
      </div>

      <Footer />

      <OurFatherDialog
        open={globalState.dialogs.ourFather}
        onClose={() => {
          globalDispatch({ type: "DIALOG_TOGGLED", key: "ourFather" })
        }}
      />
      <HailMaryDialog
        open={globalState.dialogs.hailMary}
        onClose={() => {
          globalDispatch({ type: "DIALOG_TOGGLED", key: "hailMary" })
        }}
      />
      <GloryBeDialog
        open={globalState.dialogs.gloryBe}
        onClose={() => {
          globalDispatch({ type: "DIALOG_TOGGLED", key: "gloryBe" })
        }}
      />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
